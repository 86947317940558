import React from 'react'
import { graphql } from 'gatsby'
import queryString from 'query-string'
import { PageLayout, CategoryPage } from '../components'
import { DEFAULT_LIST_PAGE } from '../constants'

const CategoryPageTemplate = ({ data, pageContext }) => {
  const { id_category, lang, pageIndex, categories, products, attributes, attribute_groups } = pageContext

  const category = categories.find(i => +i.id_category === +id_category)
  const parent = categories.find(i => +i.id_category === +category.id_parent)
  const breadcrumbs = []

  let current = parent
  do {
    if (current) {
      breadcrumbs.unshift(current)

      // @todo remove constants here
      const id_parent = current.id_parent
      if (current.id_parent && ![1].includes(+id_parent)) {
        current = categories.find(i => +i.id_category === +id_parent)
      } else {
        current = false
      }
    }
  } while (current)
  breadcrumbs.unshift('homepage')

  const navigation = { page: DEFAULT_LIST_PAGE }
  if (pageIndex) {
    navigation.page = pageIndex
  }
  if (typeof window !== 'undefined') {
    const qs = queryString.parse(window.location.search)
    navigation.page = qs.page ? +qs.page : navigation.page
  }

  return (
    <PageLayout lang={lang} switcher={category}>
      <CategoryPage
        category={category}
        parent={parent}
        children={categories.filter(i => +i.id_parent === +category.id_category)}
        products={products.filter(i => i)}
        attributes={attributes}
        attribute_groups={attribute_groups}
        breadcrumbs={breadcrumbs}
        navigation={navigation}
        lang={lang} />
    </PageLayout>
  )
}

export default CategoryPageTemplate
